import React, { useEffect, useState } from 'react';
import './SectionNews.scss';
import './mobile/index.scss';
import { Container, Grid } from '@material-ui/core';
import Link from 'next/link';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { withTranslation } from '@i18n';
import { Btn, NewsItem } from '../index';
import { getMonthsArray, languageReturner } from '../../helpers';

const SectionNews = (props: any) => {
  const { _news = [], t } = props;
  const { windowWidth, language } = useSelector((state: any) => state.info);
  const [news, setNews] = useState(_news);
  const [newsNumber, setNewsNumber] = useState(3);
  const changeNewsNumber = () => {
    setNewsNumber((windowWidth >= 480 && windowWidth < 1024) ? 4 : 3);
  };
  useEffect(():any => {
    let cleanupFunction = true;
    async function fetchData() {
      if (!news.length) {
        const newsResponse = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/news`, {
          headers: {
            'X-localization': language === 'ru' ? 'ru' : 'en',
          },
        });
        if (cleanupFunction) {
          const newsData = newsResponse.data.data;
          setNews(newsData);
        }
      }
    }
    fetchData();
    return () => (cleanupFunction = false);
  }, []);
  useEffect(() => {
    changeNewsNumber();
  }, [windowWidth]);
  return (
    <div className="main__news-section">
      <Container>
        <div className="main__news-section__header">
          <h3 className="main-page-title">{t('Новости')}</h3>
          <div className="main__news-category-button">
            <Link href="/news" as={`${languageReturner(language)}/news`}>
              <Btn variant="outlined" color="gray" className="main_button_allNews" flex justifyContent="space-between">
                {t('Все новости')}
                <i className="fa fa-caret-right ml-auto fix-mainPage-cornerRight" />
              </Btn>
            </Link>
          </div>
        </div>
        <div className="main__news">
          <Grid container spacing={3}>
            {news && news.slice(0, newsNumber).map((item: any) => {
              const newsByLang:{[index:string]:any} = {};
              const {
                id, url_title, translates, created_at,
              } = item;
              let date = new Date(created_at);
              if (Number.isNaN(date.getMonth())) {
                const arr = created_at.split(/[- :]/);
                date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
              }
              const monthIndex = date.getMonth();
              const day = date.getDate();
              const year = date.getFullYear();
              const monthName = language === 'ru' ? getMonthsArray.ru[monthIndex] : getMonthsArray.en[monthIndex];
              translates.map((translation: any) => newsByLang[translation.lang] = translation);
              const {
                title, small_picture, body, new_id,
              } = language === 'ru' ? (newsByLang as any).ru : (newsByLang as any).en;
              return (
                <NewsItem
                  key={id}
                  title={title}
                  urlTitle={{
                    pathname: '/news/[news_id]',
                    query: {
                      news_id: url_title,
                    },
                  }}
                  urlTitleAs={`${languageReturner(language)}/news/${url_title}`}
                  picture={process.env.REACT_APP_API_SERVER_URL + small_picture}
                  body={body}
                  date={`${monthName} ${day}, ${year}`}
                />
              );
            })}
          </Grid>
        </div>
      </Container>
    </div>
  );
};
export default withTranslation('common')(SectionNews);
