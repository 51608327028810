import React from 'react';
import './NewsItem.scss';
import './mobile/index.scss';
import Link from 'next/link';
import { Grid } from '@material-ui/core';
import LazyBackground from '../LazyBackground';

const cutWords = (text: any, wordsNumber: any) => text.split(' ').slice(0, wordsNumber).join(' ').toString();
type NewsItemProps = {
    title?: string;
    urlTitle?: any;
    urlTitleAs?: string;
    picture?: string;
    body?: string;
    date?: string;
};
const NewsItem = (props: NewsItemProps) => {
  const {
    title,
    urlTitle,
    urlTitleAs,
    picture,
    body,
    date,
  } = props;
  return (
    <Grid
      className="news-item"
      item
      xs={12}
      sm={6}
      md={4}
    >
      <div className="news-item_blockMain">
        <div className="news_img_block">
          <Link href={urlTitle} as={urlTitleAs}>
            <a aria-label={urlTitle}>
              <LazyBackground className="news-item__image hide-if-no-js" src={picture} height={253} />
              <noscript>
                <div className="news-item__image" style={{ backgroundImage: picture ? `url(${picture})` : 'none' }} />
              </noscript>
            </a>
          </Link>
        </div>
        <div className="news-item__date">{date}</div>
        <h3 className="news-item__title">
          <Link href={urlTitle} as={urlTitleAs}>
            <a>{title}</a>
          </Link>
        </h3>
      </div>
      <div dangerouslySetInnerHTML={{ __html: cutWords((body as any).replace(/(<([^>]+)>)/ig, ''), 15) }} className="news-item__text" />
    </Grid>
  );
};
export default React.memo(NewsItem);
